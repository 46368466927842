@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,500;1,700&display=swap");

html,
body {
  background: rgb(236, 236, 236);
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1.1em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Rubik, sans-serif;
}
h2 {
  font-size: 1.5em;
}

a {
  color: inherit;
  text-decoration: underline;
}

p {
  line-height: 1.3;
}

* {
  box-sizing: border-box;
}

.tooltip {
  opacity: 1 !important;
  box-shadow: 1px 1px 10px rgba(0,0,0,0.4);
}

